import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Paul" desc="Woodworking projects by Paul Jehn." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Welcome to Oakenshield Woodworking!</h1>
        <p>Paul is a jack of many trades, master of one: woodworking. With decades of experience ranging from helping his father build a house as a kid, to selling his own handcrafted furniture, to making toys for his granddaughter, to building his own houses, it only made sense that there be a website showcasing Paul's work. Hopefully it does his work justice, there are way more woodworking projects than there are photos on this website so stay tuned for more to be added over time.</p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
